export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'GridIcon',
  },
  {
    title: 'Product',
    route: 'product',
    icon: 'FileTextIcon',
    permission: 'vendor-category-create' || 'vendor-category-update' || 'vendor-category-delete' || 'vendor-create' || 'vendor-update' || 'vendor-delete' || 'product-unit-create' || 'product-unit-update' || 'product-unit-delete' || 'product-category-create' || 'product-category-update' || 'product-category-delete' || 'product-create' || 'product-update' || 'product-delete',
    children: [
      {
        title: 'Vendor Category',
        route: 'vendor-category',
        icon: 'ArrowRightIcon',
        permission: 'vendor-category-create' || 'vendor-category-update' || 'vendor-category-delete',
      },
      {
        title: 'Vendor',
        route: 'vendor',
        icon: 'ArrowRightIcon',
        permission: 'vendor-create' || 'vendor-update' || 'vendor-delete',
      },
      {
        title: 'Units',
        route: 'units',
        icon: 'ArrowRightIcon',
        permission: 'product-unit-create' || 'product-unit-update' || 'product-unit-delete',
      },
      {
        title: 'Categories',
        route: 'categories',
        icon: 'ArrowRightIcon',
        permission: 'product-category-create' || 'product-category-update' || 'product-category-delete',
      },
      {
        title: 'Products',
        route: 'products',
        icon: 'ArrowRightIcon',
        permission: 'product-create' || 'product-update' || 'product-delete',
      },
    ],
  },
  {
    title: 'Sales',
    route: 'sales',
    icon: 'ShoppingBagIcon',
    permission: 'sales-order-item-inquiry' || 'sales-order-item-create' || 'sales-order-item-detail' || 'sales-order-item-update' || 'sales-order-item-delete' || 'sales-order-inquiry' || 'sales-order-create' || 'sales-order-detail' || 'sales-order-update' || 'sales-order-delete' || 'sales-order-approval' || 'sales-catalog-inquiry' || 'sales-catalog-create' || 'sales-catalog-detail' || 'sales-catalog-update' || 'sales-catalog-delete' ,
    children: [
      {
        title: 'Order',
        route: 'order',
        icon: 'ArrowRightIcon',
        permission: 'sales-order-item-inquiry' || 'sales-order-item-create' || 'sales-order-item-detail' || 'sales-order-item-update' || 'sales-order-item-delete' || 'sales-order-inquiry' || 'sales-order-create' || 'sales-order-detail' || 'sales-order-update' || 'sales-order-delete' || 'sales-order-approval' ,
      },
      {
        title: 'Catalog',
        route: 'catalog',
        icon: 'ArrowRightIcon',
        permission: 'sales-catalog-inquiry' || 'sales-catalog-create' || 'sales-catalog-detail' || 'sales-catalog-update' || 'sales-catalog-delete',
      }
    ],
  },
  {
    title: 'Inventory',
    route: 'warehouse',
    icon: 'ArchiveIcon',
    permission: 'warehouse-inquiry' || 'warehouse-create' || 'warehouse-detail' || 'warehouse-update' || 'warehouse-delete' || 'warehouse-item-inquiry' || 'warehouse-item-create' || 'warehouse-item-update' || 'warehouse-item-detail' || 'warehouse-item-insert' || 'warehouse-stockopname-inquiry' || 'warehouse-stockopname-create' || 'warehouse-stockopname-detail' || 'warehouse-stockopname-update' || 'warehouse-stockopname-confirm' || 'warehouse-stockopname-reject' || 'warehouse-stockopname-delete' || 'warehouse-stockopname-item-inquiry' || 'warehouse-stockopname-item-update' || 'warehouse-stockopname-item-show',
    children: [
      {
        title: 'Warehouses',
        route: 'warehouse-location',
        icon: 'ArrowRightIcon',
        permission: 'warehouse-inquiry' || 'warehouse-create' || 'warehouse-detail' || 'warehouse-update' || 'warehouse-delete',
      },
      {
        title: 'Item',
        route: 'item',
        icon: 'ArrowRightIcon',
        permission: 'warehouse-item-inquiry' || 'warehouse-item-create' || 'warehouse-item-update' || 'warehouse-item-detail' || 'warehouse-item-insert',
      },
      {
        title: 'Stock Opname',
        route: 'stock-opname',
        icon: 'ArrowRightIcon',
        permission: 'warehouse-stockopname-inquiry' || 'warehouse-stockopname-create' || 'warehouse-stockopname-detail' || 'warehouse-stockopname-update' || 'warehouse-stockopname-confirm' || 'warehouse-stockopname-reject' || 'warehouse-stockopname-delete' || 'warehouse-stockopname-item-inquiry' || 'warehouse-stockopname-item-update' || 'warehouse-stockopname-item-show',
      },
    ],
  },
  {
    title: 'Settings',
    route: 'master',
    icon: 'SettingsIcon',
    permission: 'employee-create' || 'company-update-create' || 'organization-create' || 'organization-update' || 'organization-delete' || 'master-payment-type-create' || 'master-payment-type-update' || 'master-payment-type-delete',
    children: [
      {
        title: 'Users',
        route: 'users',
        icon: 'UsersIcon',
        permission: 'employee-create',
      },
      {
        title: 'Company',
        route: 'company',
        icon: 'BriefcaseIcon',
        permission: 'company-update-create',
      },
      {
        title: 'Organizations',
        route: 'organizations',
        icon: 'ArrowRightIcon',
        permission: 'organization-inquiry' || 'organization-create' || 'organization-detail' || 'organization-update' || 'organization-delete',
      },
      {
        title: 'Payment Type',
        route: 'payment-type',
        icon: 'ArrowRightIcon',
        permission: 'master-payment-type-inquiry' || 'master-payment-type-create' || 'master-payment-type-detail' || 'master-payment-type-update' || 'master-payment-type-delete',
      },
    ],
  },
]